import { FC, useEffect, useState } from "react";
import React from "react";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import translate from "src/lib/translate";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import Loader from "src/ui/components/Loader/Loader";
import { toast } from "src/state/state";
import { globalEvents } from "src/constants/globalEvents";
import reportErrorSentry from 'src/lib/reportErrorSentry'

const DialogIframe = styled.iframe`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  padding-bottom: 0 !important;
`;

const RescheduleAppointmentDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get("id") ?? "";

  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);
  const [iframeUrl, setIframeUrl] = useState<string | undefined>();

  const onClose = (dispatchEvent = true) => {
    if (dispatchEvent) {
      document.dispatchEvent(
          new CustomEvent(globalEvents.APPOINTMENT_RESCHEDULED, {
            bubbles: true,
            composed: true
          })
      );
    }

    void modalRef?.current?.dismiss();

    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  useEffect(() => {
    void AppointmentsBloc.getAppointmentReschedulingUrl(appointmentId)
      .then((url) => {
        setIframeUrl(url);
      })
      .catch((e: unknown) => {
        reportErrorSentry(e);

        toast.error("error_failed_appointment_reschedule");

        onClose(false);
      });
  }, []);

  if (!iframeUrl) {
    return <Loader gradient active fixed />;
  }

  return (
    <InDialog
      width="min(calc(100% - 4rem), 120rem)"
      height="min(calc(100% - 4rem), 70rem)"
      simple
      backdropDismiss={false}
      title={translate("appointment.popup.title", {
        context: "reschedule"
      })}
      onClose={onClose}
      setRef={setModalRef}
      returnUrl={returnUrl}
    >
      <DialogIframe src={iframeUrl} id="schedule-appointment" />
    </InDialog>
  );
};

export default RescheduleAppointmentDialog;
